// 导入 api
import axios from './api'

//login的登录
export const login = params => {
    return axios({
        url: 'login/index',
        method: 'post',
        params
    })
};
//获取角色列表
export const roleList = params =>{
    return axios({
        url: 'role/roleList',
        method: 'post',
        params
    })
}
//修改角色列表
export const userMenu = params =>{
    return axios({
        url: 'role/userMenu',
        method: 'post',
        params
    })
}
//增加角色
export const addRole = params =>{
    return axios({
        url: 'role/addRole',
        method: 'post',
        params
    })
}
//删除角色
export const delRole = params =>{
    return axios({
        url: 'role/delRole',
        method: 'post',
        params
    })
}
//获取树形的菜单
export const getmenu = params =>{
    return axios({
        url: 'role/getmenu',
        method: 'post',
        params
    })
}
//添加后台管理员
export const addUser = params =>{
    return axios({
        url: 'user/addUser',
        method: 'post',
        params
    })
}
//删除后台管理员
export const deluser = params =>{
    return axios({
        url: 'user/deluser',
        method: 'post',
        params
    })
}

//获取某个管理的信息
export const oneuser = params =>{
    return axios({
        url: 'user/oneuser',
        method: 'post',
        params
    })
}
//获取管理员列表
export const userList = params =>{
    return axios({
        url: 'user/userList',
        method: 'post',
        params
    })
}
//获取壁纸分类的列表
export const sortlist = params =>{
    return axios({
        url: 'sort/index',
        method: 'post',
        params
    })
}
//获取所有的图片
export const imgList = params =>{
    return axios({
        url: 'img/imgList',
        method: 'post',
        params
    })
}
//删除图片
export const delImg = params =>{
    return axios({
        url: 'img/delImg',
        method: 'post',
        params
    })
}
//上传图片到七牛云
export const  qiniuImg= params =>{
    return axios({
        url: 'tools/qiniuImg',
        method: 'post',
        params
    })
}
//删除七牛云的图片
export const  delqiniuImg = params =>{
    return axios({
        url: 'tools/delQiniuImg',
        method: 'post',
        params
    })
}
//修改分类
export const  editSort = params =>{
    return axios({
        url: 'sort/edit',
        method: 'post',
        params
    })
}
//获取专题的列表
export const  zhuantiList = params =>{
    return axios({
        url: 'zhuanti/list',
        method: 'post',
        params
    })
}
//添加专题
export const  zhuantiAdd = params =>{
    return axios({
        url: 'zhuanti/add',
        method: 'post',
        params
    })
}
//删除专题的操作
export const  zhuantiDel = params =>{
    return axios({
        url: 'zhuanti/del',
        method: 'post',
        params
    })
}
//获取banner列表
export const  bannerList = params =>{
    return axios({
        url: 'banner/bannerList',
        method: 'post',
        params
    })
}
//添加banner的图片
export const  bannerAdd = params =>{
    return axios({
        url: 'banner/add',
        method: 'post',
        params
    })
}
//删除banner图片
export const  bannerDel = params =>{
    return axios({
        url: 'banner/del',
        method: 'post',
        params
    })
}
//获取图片的分类列表
export const  imgSort = params =>{
    return axios({
        url: 'img/imgSort',
        method: 'post',
        params
    })
}
//根据分类的ID查询对应的图片
export const  oneSort = params =>{
    return axios({
        url: 'img/oneSort',
        method: 'post',
        params
    })
}
//把图片保存到mysql的操作
export const  imgtoDb = params =>{
    return axios({
        url: 'img/imgtoDb',
        method: 'post',
        params
    })
}


// 默认全部倒出
export default {
    login,
    roleList,
    userMenu,
    addRole,
    delRole,
    getmenu,
    addUser,
    deluser,
    oneuser,
    userList,
    sortlist,
    imgList,
    delImg,
    delqiniuImg,
    editSort,
    zhuantiList,
    zhuantiAdd,
    zhuantiDel,
    bannerList,
    bannerAdd,
    bannerDel,
    imgSort,
    oneSort,
    imgtoDb
}