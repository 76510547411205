<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {}
  },
  created() {},
  mounted() {}
}
</script>
<style>
@charset "UTF-8";
* {
  padding: 0px;
  margin: 0px;
}
::-moz-selection {
  background: #009688;
  color: #fff;
}
::selection {
  background: #159b76;
  color: #fff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #009688;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
</style>
