<template>
  <div class="pageBg">
    <div class="loginBox">
      <div class="topBox">
        <img src="../../assets/login-icon.png" alt />
      </div>
      <div class="middleBox">
        <span>云趣壁纸后台</span>
      </div>
      <div class="bottomBox">
        <el-form ref="form" :model="form" class="loginForm">
          <el-form-item label>
            <el-input
              v-model="form.userName"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label>
            <el-input
              v-model="form.userPassword"
              type="password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-button type="danger" @click="onSubmit" class="butonBox"
            >登录</el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      form: {
        userName: "",
        userPassword: "",
      },
	   userInfo: {
        userName: 'admin',
        password: 123
      }
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onSubmit: function () {
      if (this.form.userName && this.form.userPassword) {
        
        //登录
        let params = {
          userName: this.form.userName,
          userPassword: this.form.userPassword,
        }
        this.$api.login(params).then(res=>{
            let response =  JSON.parse(res);
             if (response.data.code == 200) {
              //保存菜单数据
              this.$message({
                message: response.data.msg,
                duration: 1300,
                type: "success",
                onClose: () => {
                  //存储token和设置存储路由
                  localStorage.setItem("token", response.data.token);
                  localStorage.setItem("userName", response.data.data.userName);
                  sessionStorage.setItem("token",response.data.token);
                  localStorage.setItem("menuList",JSON.stringify(response.data.menuList));
                  this.$store.dispatch('login', response.data).then(() => {
                    this.$router.push({ path: '/home' })
                  })
                },
              });
            } else if (response.data.code == 201) {
              this.$message.error(response.data.msg);
            }
        }).catch(err=>{
            alert(err);
        });
      } else {
        this.$message.error("请先输入信息");
      }
    },
  },
  components: {},
};
</script>

<style scoped >
.pageBg {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: url("../../assets/login_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.loginBox {
  background: white;
  border-radius: 4px;
  display: flex;
  width: 400px;
  height: 420px;
  flex-direction: column;
}
.topBox {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.topBox img {
  width: 80px;
  height: 80px;
}
.middleBox {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
}
.middleBox span {
  font-size: 20px !important;
  font-family: "Microsoft YaHei";
  text-shadow: rgb(0 0 0 / 15%) 0 0 1px;
  font-weight: 400;
  color: #37474f;
}
.bottomBox {
  display: flex;
  flex: 1;
  padding-top: 10px;
}
.loginForm {
  width: 100%;
  padding: 20px 40px;
}
.el-button--danger {
  width: 100%;
  height: 46px;
}
.butonBox {
  margin-top: 20px;
}
</style>
