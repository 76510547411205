<template>
	<div class="mainBox">
		<el-container class="layoutBox">
			<!--左边菜单栏目-->
			<el-aside :width="this.$store.state.barWidth">
				<menuList></menuList>
			</el-aside>
			<el-container class="rightBox">
				<el-header>
					<headerBox></headerBox>
				</el-header>
				<el-main class="contentBox">
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
import menuList from "../../components/menuList";
import headerBox from "../../components/headerBox";
export default {
	components: {
		menuList,
		headerBox
	},
	created() {
    // // 监听改变菜单栏折叠
    // this.$root.Bus.$on('toggle', value => {
	// console.log("1111111点击了");
    //   if (value) {
    //     this.showclass = 'asideshow'
    //   } else {
    //     setTimeout(() => {
    //       this.showclass = 'aside'
    //     }, 300)
    //   }
    // })
  },
};
</script>

<style>
.mainBox {
	/*程序主界面的盒子 */
	height: 100vh;
}
.layoutBox {
	height: 100%;
}
.rightBox{
	background-color: #f2f2f2;
}
.el-header{
	padding: 0px !important;
}
.rightBox .contentBox{
	background: white;
	margin:0px 10px 10px 10px;
	border-radius: 4px;
}
</style>