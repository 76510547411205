<template>
  <div class="menuBox">
    <el-col :span="24">
      <div class="titleBox"><i class="el-icon-platform-eleme" style="margin-right:6px;font-size:24px"></i>{{ this.$store.state.title }}</div>
      <el-menu
        :default-active="$route.path"
        router
        class="el-menu-vertical-demo"
        :unique-opened="true"
        @open="handleOpen"
        @close="handleClose"
        :collapse-transition="false"
        :collapse="this.$store.state.menuTag"
        background-color="#041427"
        text-color="#BBB"
        active-text-color="#4a8ae9"
        active-background-color="#000000"
      >
      <template  v-for="item in menu">

        <!-- 没有子元素children -->
          <el-menu-item :index="item.path" v-if="!item.children">
            <i :class="'el-icon-' + item.icon"></i>
            <span slot="title">{{ item.label }}</span>
          </el-menu-item>

           <!-- 有children的元素时候 -->
          <el-submenu :index="item.id + '' " v-else >
              <template slot="title">
                <i :class="'el-icon-' + item.icon"></i>
                <span>{{ item.label }}</span>
              </template>
            <el-menu-item
              v-for="item1 in item.children"
              :key="item1.id"
              :index="item1.path"
            >
              <i :class="'el-icon-' + item1.icon"></i>
              <span slot="title">{{ item1.label }}</span>
            </el-menu-item>
          </el-submenu>

      </template>
      </el-menu>
    </el-col>
  </div>
</template>

<script>
export default {
  name: "menuList",
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    }
  },
  computed: {
    //   计算是否有children
    noChildren() {
      return this.menu.filter((item) => !item.children)
    },
    //传进一个对象判断是否有子元素
    hasChildren(itemOne) {
      if (itemOne.children) {
        return true
      } else {
        return false
      }
    },
    menu() {
      let menuObj = localStorage.getItem("menuList")
      return JSON.parse(menuObj)
    }
  }
}
</script>

<style>
.el-menu-vertical-demo{
  height: 620px;
  overflow: hidden;
}
.menuBox {
  text-align: left;
  height: 100%;
  background-color: #041427;
}
.menuBox .titleBox {
  font-weight: bold;
  background-color: #041427;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
}
.el-menu {
  border-right: none !important;
  padding: 0px 6px 0px 6px !important;
}
.twoList .el-menu-item {
  /**二级导航距离左边 */
  padding-left: 34px !important;
  min-width: 0px !important;
}
.twoList .el-menu-item-group__title {
  /** 第一个头部空白 */
  padding: 0px !important;
}
.el-submenu .el-menu-item{
  min-width: 0px !important;
  padding-left: 14px !important;
}
.is-active{
  background: #4a8ae9 !important;
  color: white !important;
  border-radius: 3px;
}
.el-menu-item{
    height: 44px !important;
    line-height: 44px !important;
}
</style>