import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import Main from "../views/main/index.vue" 
import Update from "../views/update/index.vue"
import login from '../views/login/index.vue'
import nullPage from '../views/404/index.vue'

Vue.use(VueRouter)
//模拟权限路由

//初始的路由，所有人可见
export const constantRoutes = [
  {
    path: '/Main',
    name: 'main',
    component: Main,
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
      },
      {
        path: '/update',
        name: 'update',
        component: Update,
      }
    ]
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/404',
    name: '404',
    component: nullPage
  }
  // 404 page must be placed at the end !!!
  //{ path: '*', redirect: '/404', hidden: true }
]

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

export const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
