<template>
  <div class="mainBoxTop">
    <div class="clickBar">
      <!-- 折叠菜单 -->
      <i class="clickBtn" :class="iconTag == false ? ' el-icon-s-fold':'el-icon-s-unfold'" @click="chagnge()"></i>
    </div>
    <div class="headerBox">
      <!-- 更新日志 -->
      <router-link to="/update">
        <div class="updateBox">
          <span>更新日志</span>
        </div>
      </router-link>
      <!-- 用户信息 -->
      <div
        class="userInfo"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
        :style="active"
      >
        <span>欢迎你，{{ userName }}</span>
      </div>
      <!-- 退出按钮 -->
      <div
        class="outBox"
        @mouseover="mouseOver1"
        @mouseleave="mouseLeave1"
        :style="active1"
        @click="loginOut"
      >
        <i class="el-icon-location"></i>
        <span slot="title">退出</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerBox",
  data() {
    return {
      active: "",
      active1: "",
      userName: "",
      iconTag:false
    };
  },

  methods: {
    mouseOver: function () {
      this.active = "background-color: #f7f7f7;cursor:pointer";
    },
    mouseLeave: function () {
      this.active = "";
    },
    mouseOver1: function () {
      this.active1 = "background-color: #f7f7f7;cursor:pointer";
    },
    mouseLeave1: function () {
      this.active1 = "";
    },
    loginOut: function () {
      this.$message("用户退出成功");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userName");
      window.localStorage.removeItem("vuex");

      this.$store.dispatch('logout')
      this.$router.push('/login')
    },
    chagnge() {
      this.$store.state.menuTag = !this.$store.state.menuTag;
      this.iconTag = !this.iconTag;
      if ((this.$store.state.menuTag)) {
        this.$store.state.title = "";
        this.$store.state.barWidth = '64px';
      } else {
        this.$store.state.title = "云趣壁纸后台";
        this.$store.state.barWidth = '200px';
      }
    },
  },
  mounted() {
    this.userName = window.localStorage.getItem("userName");
  },
};
</script>

<style>
.mainBoxTop {
  display: flex;
  background: #ffffff;
}
.clickBar {
  display: flex;
  flex: 1;
  align-items: center;
}
.headerBox {
  display: flex;
  flex: 1;
  height: 52px;
  justify-content: flex-end;
  background: #ffffff;
  align-items: center;
  padding-right: 20px;
  font-size: 14px;
}
.clickBtn {
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}
.headerBox .userInfo {
  display: flex;
  padding: 0px 10px;
  align-items: center;
}
.headerBox .outBox {
  display: flex;
  padding: 0px 10px;
  align-items: center;
}
.updateBox {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.headerBox a {
  text-decoration: none;
  color: #000;
}
</style>