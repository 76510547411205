import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'

import http from './http/index'
Vue.use(http)


import "element-ui/lib/theme-chalk/index.css";  //引入elementui
import axios from 'axios'     //引入axios
import VueAxios from 'vue-axios';  // 引入vue-axios
import * as echarts from 'echarts'; //引入echarts主题
import './permission'             //权限菜单
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
Vue.use(ElementUI)
Vue.use(VueAxios,axios); 

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
