<template>
  <div>
    <div class="titleBoxTip">
      <h3>更新日志</h3>
    </div>
    <div class="itemOneBox" v-for="item in updateList" :key="item.id">
      <div class="dateBox">
        <div class="versionBox">
          <span>{{ item.version }}</span>
        </div>
        <div class="riqiBox">
          <span>{{item.record_time}}</span>
        </div>
      </div>
      <!-- 下边的更新内容的盒子 -->
      <div class="updateBox1">
        <p v-for="(item1,index) in item.content" :key="index">
          {{item1}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      updateList: [1, 2],
    };
  },
  mounted() {
    //获取字典的list
    this.axios
      .post("https://jk.phonenew.cn/api/home/updateinfo", {
        token: window.localStorage.getItem("token"),
      })
      .then((response) => {
        this.updateList = response.data.data;
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>

<style>
.itemOneBox {
  display: flex;
  margin-top: 20px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  flex-direction: column;
  border-radius: 4px;
}
.dateBox {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  border-bottom: 1px solid #ddd;
}
.versionBox {
  display: flex;
  font-size: 20px;
  padding: 15px 20px;
  font-weight: 700;
  flex: 1;
}
.riqiBox {
  display: flex;
  padding: 15px 20px;
  justify-content: flex-end;
  flex: 1;
}
.updateBox1 {
  padding: 10px 20px 30px 20px;
}
</style>